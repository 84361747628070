import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/AuthContext";
import { BiSolidDownArrow } from "react-icons/bi";
import Dropdown from "./Dropdown";
import { aboutUs, getInvolved, ourWork } from "../../data/dropdownLink";
import { CgProfile } from "react-icons/cg";

const Navlinks = () => {
  const navigate = useNavigate();

  const { user, isAuthenticated } = useUserContext();

  const [hoveredId, setHoveredId] = useState(null);

  const queryParams = { redirect: "/" };
  return (
    <div className="hidden lg:flex items-center gap-[70px] text-lg">
      {/* <div
        onMouseEnter={() => setHoveredId(1)}
        onMouseLeave={() => setHoveredId(null)}
        className="relative cursor-pointer flex items-center gap-[5px]"
      >
        <p className="flex items-center gap-[5px] font-semibold text-[16px] tracking-wider cursor-pointer">
          OUR WORK
          <BiSolidDownArrow className="text-[12px]" />
        </p>
        {hoveredId === 1 && <Dropdown data={ourWork} />}
      </div>

      <div
        onMouseEnter={() => setHoveredId(2)}
        onMouseLeave={() => setHoveredId(null)}
        className="relative cursor-pointer flex items-center gap-[5px]"
      >
        <p className="flex items-center gap-[5px] font-semibold text-[16px] tracking-wider cursor-pointer">
          ABOUT US
          <BiSolidDownArrow className="text-[12px]" />
        </p>
        {hoveredId === 2 && <Dropdown data={aboutUs} />}
      </div>

      <div
        onMouseEnter={() => setHoveredId(3)}
        onMouseLeave={() => setHoveredId(null)}
        className="relative cursor-pointer flex items-center gap-[5px]"
      >
        <p className="flex items-center gap-[5px] font-semibold text-[16px] tracking-wider cursor-pointer">
          GET INVOLVED
          <BiSolidDownArrow className="text-[12px]" />
        </p>
        {hoveredId === 3 && <Dropdown data={getInvolved} />}
      </div> */}

      {isAuthenticated ? (
        <Link to={`/profile/${user?.id}`}>
          <div className="flex items-center gap-[5px] px-[10px] py-[7px] rounded-[25px] border border-slate-300 ">
            <CgProfile className="text-[25px]" />
            <p className="hidden md:block">{user.name}</p>
          </div>
        </Link>
      ) : (
        <div
          style={{
            zIndex: "20",
          }}
          className="flex items-center gap-[30px]"
        >
          <div className="flex items-center gap-[20px]">
            <button
              onClick={() => {
                navigate(`/register`);
              }}
              className="whitespace-nowrap bg-slate-400 px-[20px] py-[8px] rounded-[4px] "
            >
              Sign Up
            </button>
            <button
              onClick={() => {
                const queryString = Object.entries(queryParams)
                  .map(([key, value]) => `${key}=${value}`)
                  .join("&");
                navigate(`/login?${queryString}`);
              }}
              className="bg-darkBlue text-white px-[20px] py-[8px] rounded-[4px] "
            >
              Login
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navlinks;
