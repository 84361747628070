export const createUserAccount = async (userInfo) => {
  const data = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/register`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userInfo),
    }
  )
    .then((res) => res.json())
    .then((res) => {
      return res;
    });

  return data;
};

// Login to account
export const loginUserAccount = async (userInfo) => {
  const data = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userInfo),
    }
  )
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
  return data;
};

// Login to account
export const changePassword = async (info, token) => {
  const data = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/change-password`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(info),
    }
  )
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
  return data;
};

// Upload a Voucher
export const uploadVoucher = async (formData, token) => {
  const voucher = formData.get("voucher");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1/bidder-file`,
      {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          voucher: formData.get("voucher"),
        }),
      }
    );
  } catch (error) {
    console.error("Error uploading voucher:", error);
    // Handle the error appropriately (throw, log, etc.)
  }
};

// user decrypt log
export const sendUserDecryptLog = async (info, token) => {
  const { device_info, bidId } = info;

  try {
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1/logs-data-store/${bidId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          device_info,
        }),
      }
    );
  } catch (error) {
    console.error("Error uploading voucher:", error);
    // Handle the error appropriately (throw, log, etc.)
  }
};
