export const dateTimeFormatter = (dateString) => {

  const dateTime = new Date(dateString);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const formattedDateTime = dateTime.toLocaleDateString("en-US", options);
  return formattedDateTime;
};
