import React from "react";

const Baner1 = ({ image, title, desc, btnName }) => {
  return (
    <div className="relative w-full h-[500px] lg:h-[300px] text-white rounded-[10px] overflow-hidden">
      <img
        src={image}
        alt="bannerImage"
        className="z-[-10] w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover"
      />
      <div className="w-full h-full flex flex-col items-center justify-center gap-[16px] text-center">
        <h1 className="max-w-[60%] text-3xl font-semibold">{title}</h1>
        <p className="max-w-[60%] ">{desc}</p>
        <button className="bg-darkBlue px-[20px] py-[8px] rounded-[25px] ">{btnName}</button>
      </div>

      <div className="z-[-1] absolute top-0 left-0 h-full w-[50%] bg-gradient-to-l from-black to-transparent" />
      <div className="z-[-1] absolute top-0 right-0 h-full w-[50%] bg-gradient-to-r from-black to-transparent" />
    </div>
  );
};

export default Baner1;
