import React, { useEffect, useState } from "react";
import TendersTable from "../components/Tenders/TendersTable";
import { IoIosArrowDown } from "react-icons/io";
import { useUserContext } from "../context/AuthContext";
import Loader from "../components/Loader";

const TenderList = () => {
  const { user, token } = useUserContext();

  const [tenderList, setTenderList] = useState([]);
  const [activeId, setActiveId] = useState(0);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [diplayDepositPopup, setDiplayDepositPopup] = useState(false);
  const [activeTenderDetails, setActiveTenderDetails] = useState();
  const [status, setStatus] = useState({
    pending: true,
    success: false,
    error: "",
  });
  const [bankDetails, setBankDetails] = useState([]);
  const [applyDirectly, setApplyDirectly] = useState(false);

  const getTendersData = async () => {
    setStatus({
      pending: true,
      success: false,
      error: "",
    });
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/categories`
      )
        .then(async (res) => await res.json())
        .then((response) => {
          setStatus({
            pending: false,
            success: true,
            error: "",
          });
          return response;
        });
      setTenderList(data.categories);

      return data.categories;
    } catch (err) {
      setStatus({
        pending: false,
        success: false,
        error: "Something went wrong!",
      });
    }
  };

  const getBankDetails = async () => {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/bank-details`
      )
        .then(async (res) => await res.json())
        .then((res) => res.bank_details);
      setBankDetails(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getBankDetails();
    getTendersData();
  }, []);

  return (
    <div className="w-full md:w-[80%] min-h-[100vh] flex flex-col items-start px-[20px]">
      {(status.pending) && (
        <div className="w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      )}

      {(!status.pending && tenderList?.length > 0) && (
        tenderList.map((item, index) => (
          <div key={index} className="w-full">
            <div
              onClick={() => {
                if (activeId === index) {
                  setActiveId();
                  return;
                }
                setActiveId(index);
              }}
              className="w-full lg:w-max group cursor-pointer flex items-center gap-[10px] py-[10px]"
            >
              <div className="p-[10px] w-[35px] h-[35px] flex items-center justify-center rounded-full bg-blue-900 text-white">
                {index + 1}
              </div>
              <h3 className="group-hover:scale-105 group-hover:text-blue-900 text-xl font-semibold transition duration-150 ease-out">
                {item?.name}
              </h3>
              <IoIosArrowDown className="text-blue-900" />
            </div>

            {activeId === index && item?.tender_lists?.length > 0 ? (
              <div className="w-full lg:px-[20px]">
                <TendersTable
                  data={item?.tender_lists}
                  isActive={activeId === index ? true : false}
                  displayAuthPopup={() => setShowAuthPopup(true)}
                  popAlreadyApplied={() => setAlreadyApplied(true)}
                  displayDepositPopup={(tenderDetails) => {
                    setDiplayDepositPopup(true);
                    setActiveTenderDetails(tenderDetails);
                  }}
                  bank_details={bankDetails}
                  applyDirectly={(tenderDetails) => {
                    setApplyDirectly(true);
                    setActiveTenderDetails(tenderDetails);
                  }}
                />
              </div>
            ) : null}
          </div>
        ))
      )}

      {(tenderList.length === 0 && !status.pending) && (
        <div className="w-full h-[70vh] flex flex-col items-center justify-center">
          <p className="text-xl font-bold text-darkBlue">No tenders found</p>
        </div>
      )}
    </div>
  );
};

export default TenderList;
