import React from "react";
import { RxCross2 } from "react-icons/rx";
import Loader from "../Loader";

const ChangePasswordPopup = (props) => {
  const {
    isChangingPassword,
    handleCross,
    formData,
    loading,
    success,
    error,
    onFormSubmit,
    onFormChange,
    errorMessage,
  } = props;

  return (
    <div className="z-30 w-[95%] md:w-[450px] fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] flex flex-col gap-[20px] bg-white p-[20px] pb-[40px] rounded-lg shadow">
      <div className="w-full">
        <div
          onClick={handleCross}
          className="cursor-pointer float-right w-[35px] h-[35px] rounded-full border border-slate-800 flex items-center justify-center "
        >
          <RxCross2 className="text-[17px] font-bold" />
        </div>
      </div>
      <h1 className="text-xl font-semibold">Change Password</h1>
      <form onSubmit={onFormSubmit} className="flex flex-col gap-[20px]">
        <input
          type="password"
          placeholder="Old password"
          name="old_password"
          required
          value={formData.old_password || ""}
          onChange={onFormChange}
          className="p-[8px] rounded-[4px] outline-none border border-slate-400 "
        />

        <input
          type="password"
          placeholder="New password"
          name="new_password"
          required
          value={formData.new_password || ""}
          onChange={onFormChange}
          className="p-[8px] rounded-[4px] outline-none border border-slate-400 "
        />

        <input
          type="password"
          placeholder="Confirm password"
          name="confirm_password"
          required
          value={formData.confirm_password || ""}
          onChange={onFormChange}
          className="p-[8px] rounded-[4px] outline-none border border-slate-400 "
        />

        <div className="flex items-center gap-[20px] ">
          <button
            type="submit"
            disabled={loading || isChangingPassword}
            className={`px-[20px] py-[8px] h-[45px] rounded-[4px] ${
              loading || isChangingPassword ? "bg-darkBlue" : "bg-darkBlue"
            } text-white `}
          >
            {isChangingPassword || loading ? <Loader /> : "Change Password"}
          </button>
        </div>

        {success && <p>Password Updated successfully!</p>}
        {(error || errorMessage) && (
          <p className="text-red-500">Error: {errorMessage || error}</p>
        )}
      </form>
    </div>
  );
};

export default ChangePasswordPopup;
