import React from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";

const SuccessPopup = ({ handleCross, text, link }) => {
  return (
    <div className="z-30 w-full md:w-[450px] fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] flex flex-col gap-[20px] bg-white p-[20px] pb-[40px] rounded-lg shadow">
      <div className="w-full">
        <div
          onClick={handleCross}
          className="cursor-pointer float-right w-[35px] h-[35px] rounded-full border border-slate-800 flex items-center justify-center "
        >
          <RxCross2 className="text-[17px] font-bold" />
        </div>
      </div>

      <div className="w-full flex flex-col items-center gap-[20px]">
        <h1>{text}</h1>
        {link && (
          <Link
            to={link}
            className="bg-darkBlue px-[20px] py-[8px] text-white rounded-[4px] "
          >
            Go Back
          </Link>
        )}
      </div>
    </div>
  );
};

export default SuccessPopup;
