import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

const AuthPopup = ({ handleCross, redirectUrl }) => {
  const navigate = useNavigate()

  const queryParams = { redirect: redirectUrl };

  return (
    <div className="z-30 w-full md:w-[450px] fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] flex flex-col gap-[20px] bg-white p-[20px] pb-[40px] rounded-lg shadow">
      <div className="w-full">
        <div
          onClick={handleCross}
          className="cursor-pointer float-right w-[35px] h-[35px] rounded-full border border-slate-800 flex items-center justify-center "
        >
          <RxCross2 className="text-[17px] font-bold" />
        </div>
      </div>

      <div>
        <h1 className="text-xl font-bold">You are not logged In.</h1>
        <p className="text-[15px] text-slate-500 ">
          Please login to your account to apply for the tender.
        </p>
        <button
          onClick={() => {
            const queryString = Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&');
            navigate(`/login?${queryString}`)
          }}
          className="mt-[20px] bg-darkBlue text-white px-[20px] py-[8px] rounded-[4px] "
        >
          Login
        </button>
        <div className="mt-[20px] w-max flex flex-col gap-[10px] ">
          <p>{`Don't`} have account ?</p>
          <button
            onClick={() => {
              navigate(`/register`)
            }}
            className="whitespace-nowrap bg-slate-400 px-[20px] py-[8px] rounded-[4px] "
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthPopup;
