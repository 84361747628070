import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header/Navbar";
import Footer from "./components/Footer";
import Profile from "./pages/Profile";
import BidderDetailsForm from "./pages/BidderDetailsForm";
import TermsAndCondition from "./pages/TermsAndCondition";
import Toast from "./components/Popup/Toast";
import { useToastContext } from "./context/ToastContext";
import { useEffect } from "react";
import TenderList from "./pages/TenderList";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { useUserContext } from "./context/AuthContext";
import ResetPassword from "./pages/ResetPassword";
import TenderDetails from "./pages/TenderDetails";

function WindowScrollTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}

function App() {
  const { toastMessage } = useToastContext();
  const { isAuthenticated } = useUserContext();

  return (
    <div className="w-full flex flex-col items-center">
      <WindowScrollTop />
      <Header />
      <div className="w-full h-[110px] lg:h-[140px]" />
      <Toast message={toastMessage} />
      <Routes>
        <Route path="/" element={isAuthenticated ? <Home /> : <Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/reset-password"
          element={!isAuthenticated ? <ResetPassword /> : null}
        />
        <Route
          path="/profile/:slug"
          element={isAuthenticated ? <Profile /> : <Login />}
        />
        <Route path="/bidder-details-form" element={<BidderDetailsForm />} />
        <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route
          path="/tender-list"
          element={isAuthenticated ? <TenderList /> : <Login />}
        />
        <Route
          path="/tender-details/:slug"
          element={isAuthenticated ? <TenderDetails /> : <Login />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
