import { useContext, useEffect, useState } from "react";
import { createContext } from "react";

const AppliedTendersContext = createContext(null);

export function AppliedTendersProvider({ children }) {
  const [appliedTendersId, setAppliedTendersId] = useState([]);

  const handleAppliedTender = (data) => {
    const ids = data.map((item, index) => {
      return item?.tender_details?.id;
    });

    if (ids?.length > 0) {
      setAppliedTendersId(ids);
      localStorage.setItem("appliedTendersID", JSON.stringify(ids));
    } else {
      setAppliedTendersId([]);
    }
  };

  const getAppliedTendersID = () => {
    const currentAppliedIds = localStorage.getItem("appliedTendersID");
    const data = JSON.parse(currentAppliedIds);
    if (data?.length > 0) {
      setAppliedTendersId(data);
      return data;
    } else {
      setAppliedTendersId([]);
      return [];
    }
  };

  const addAppliedTenderId = (id)=>{
    setAppliedTendersId(prev=>{
      return [...prev,id]
    })
    const ids = [...appliedTendersId,id];

    localStorage.setItem("appliedTendersID", JSON.stringify(ids));
  }

  useEffect(() => {
    getAppliedTendersID()
  }, [])
  
  const value = {
    appliedTendersId,
    setAppliedTendersId,
    handleAppliedTender,
    addAppliedTenderId
  };

  return (
    <AppliedTendersContext.Provider value={value}>
      {children}
    </AppliedTendersContext.Provider>
  );
}

export const useAppliedTendersContext = () => useContext(AppliedTendersContext);
