import React, { useState } from "react";
import InputWithLabel from "../Elements/InputWithLabel";

const CompanyContactPerson = ({ handleFormChange, formData }) => {
  return (
    <div className="w-[98%] md:w-[75%]">
      <h2 className="font-semibold text-2xl pb-[30px] text-darkBlue underline underline-offset-8 decoration-darkBlue">
        Company Contact Person Info:
      </h2>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[12px] lg:gap-[30px]">
        <InputWithLabel
          label="First Name"
          inputPlaceholder="First Name"
          required={true}
          type="text"
          name="f_name"
          value={formData?.f_name}
          handleInputChange={handleFormChange}
        />
        <InputWithLabel
          label="Last Name"
          inputPlaceholder="Last Name"
          required={true}
          type="text"
          name="l_name"
          value={formData?.l_name}
          handleInputChange={handleFormChange}
        />
        <InputWithLabel
          label="Position"
          inputPlaceholder="Your Position in Company"
          type="text"
          name="position"
          value={formData?.position}
          handleInputChange={handleFormChange}
        />
        <InputWithLabel
          label="Mobile Number"
          inputPlaceholder="Mobile Number"
          required={true}
          type="number"
          name="mobile"
          value={formData?.mobile}
          handleInputChange={handleFormChange}
          
        />
        <InputWithLabel
          label="Telephone Number"
          inputPlaceholder="Telephone Number"
          type="number"
          name="phone1"
          value={formData?.phone1}
          handleInputChange={handleFormChange}
        />
        <InputWithLabel
          label="Email"
          inputPlaceholder="youremail@domain.com"
          required={true}
          type="email"
          name="contact_email"
          value={formData?.contact_email}
          handleInputChange={handleFormChange}
        />
      </div>
    </div>
  );
};

export default CompanyContactPerson;
