import React, { useEffect, useState } from "react";

const FileInputWithLabel = ({ label, name, required, handleFileSelect }) => {
  const [file, setFile] = useState();
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [fileType, setFileType] = useState();

  function handleFileChange(event) {
    const selectedFile = event.target.files[0];
    const name = event.target.name;

    if (!selectedFile) return;
    setFile(selectedFile);
    setFileType(selectedFile.type);
    const imageUrl = URL.createObjectURL(selectedFile);
    setUploadedImageUrl(imageUrl);

    handleFileSelect(selectedFile, name);
  }

  return (
    <div className="w-full h-max flex flex-col gap-[5px]">
      <p className="font-semibold">{`${label} (jpeg, png, pdf)`}</p>
      <div className="w-full flex flex-col gap-[5px] mt-[3px]">
        <label
          htmlFor={`upload-resume-${label}`}
          className="cursor-pointer bg-transparent outline-dashed outline-1 px-[20px] py-[8px] rounded-[4px] "
        >
          <p>{file?.name || label}</p>
        </label>
        <input
          id={`upload-resume-${label}`}
          type="file"
          name={name}
          required={required}
          accept="image/jpeg, image/png,.pdf"
          placeholder="Attach Resume"
          onChange={handleFileChange}
          className="custom-file-input"
        />
      </div>

      {file && uploadedImageUrl && (
        <div className="relative w-full h-[150px]">
          {fileType === "application/pdf" ? (
            <div className="w-full">
              <a
                href={uploadedImageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <iframe
                  title="Selected File"
                  src={uploadedImageUrl}
                  className="mt-4 absolute top-0 left-0 w-full h-full object-cover rounded-[10px] shadow-lg shadow-darkBlue "
                ></iframe>
              </a>
            </div>
          ) : (
            <div className="w-full">
              <a
                href={uploadedImageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={uploadedImageUrl}
                  alt="UploadedImage"
                  className="mt-4 absolute top-0 left-0 w-full h-full object-cover rounded-[10px] shadow-lg shadow-darkBlue "
                />
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileInputWithLabel;
