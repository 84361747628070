import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useUserContext } from "../../context/AuthContext";
import { getCurrentDateTime } from "../../utils/getCurrentDateTime";
import { useToastContext } from "../../context/ToastContext";
import UploadFileCard from "../Elements/UploadFileCard";
import MultiSelectWithCheckboxes from "../Elements/MultipleSelect";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import { findRenderedDOMComponentWithTag } from "react-dom/test-utils";

const UploadDocument = ({ data, handleCross }) => {
  const navigate = useNavigate();
  const { token, user } = useUserContext();
  const { setShowToast, setToastMessage } = useToastContext();
  const [status, setStatus] = useState({
    pending: false,
    success: false,
    error: "",
  });
  const [formData, setFormData] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Handle Form data
  const handleFormData = (e) => {
    const { name } = e.target;

    const inputType = e.target.type;

    if (inputType == "file") {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: e.target.files[0],
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: e.target.value,
        };
      });
    }
  };

  // Handle Checkbox
  const handleCheckboxChange = (optionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        return prevSelectedOptions.filter((id) => id !== optionId);
      } else {
        return [...prevSelectedOptions, optionId];
      }
    });
  };

  // Handle Form Submit
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    formDataToSend.append(
      "tax_clearance_certificate",
      formData.tax_clearance_certificate
    );
    formDataToSend.append(
      "registration_certificate",
      formData.registration_certificate
    );
    formDataToSend.append("experience_letter", formData.experience_letter);
    formDataToSend.append("bidfile1", formData.file1);
    formDataToSend.append("bidfile2", formData.file2);
    formDataToSend.append("bidfile3", formData.file3);
    formDataToSend.append("bidfile4", formData.file4);
    formDataToSend.append("bidfile5", formData.file5);
    formDataToSend.append("bidfile6", formData.file6);
    formDataToSend.append("bidfile7", formData.file7);
    formDataToSend.append("bidfile8", formData.file8);
    formDataToSend.append("voucher", formData.voucher);

    formDataToSend.append("date_submitted", getCurrentDateTime());
    formDataToSend.append("comment", formData.comment);
    // formDataToSend.append("item_id", selectedOptions );
    selectedOptions.forEach((optionId, index) => {
      formDataToSend.append(`item_id[${index}]`, optionId);
    });

    setStatus({
      pending: true,
      success: false,
      error: "",
    });
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/bids/${data.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formDataToSend,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            setStatus({
              pending: false,
              success: true,
              error: "",
            });

            setShowToast(true);
            setToastMessage("Document Uploaded Successfully.");
            navigate(`/profile/${user.id}`);
          } else {
            setStatus({
              pending: false,
              success: false,
              error: data.message,
            });

            if (data.errors) {
              Object.keys(data.errors).forEach((key) => {
                setStatus({
                  pending: false,
                  success: false,
                  error: `Validation Error - ${data.errors[key][0]}`,
                })


              });
            }
          }
        });
    } catch (err) {
      console.log(err);
      setStatus({
        pending: false,
        success: false,
        error: err.message,
      });
    }
  };

  return (
    <div className="z-30 bg-slate-100 w-full md:w-[75%] h-[95vh] overflow-y-auto fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] flex flex-col gap-[20px] p-[20px] pb-[40px] rounded-lg shadow">
      <div className="sticky top-0 w-full">
        <div
          onClick={handleCross}
          className="cursor-pointer float-right w-[35px] h-[35px] bg-white rounded-full border border-darkBlue flex items-center justify-center "
        >
          <RxCross2 className="text-[17px] font-bold" />
        </div>
      </div>
      <div>
        <p className="text-lg font-semibold bg-slate-200 p-[8px]">
          Please fill all the necessary details to apply for the bid.
        </p>
      </div>
      <form
        onSubmit={handleFormSubmit}
        className="w-full flex flex-col space-y-[30px]"
      >
        <label className="text-start text-2xl font-bold text-blue-500">
          Upload Files
          <span className="ml-4 text-sm text-red-400">
            Please compress and upload your PDF file. If the file size exceeds 5 MB, it may take longer to upload.
          </span>
        </label>
        <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-[20px] flex-wrap ">
          <div>
            <UploadFileCard
              handleUploadFile={handleFormData}
              inputName={"tax_clearance_certificate"}
              label={" Upload Tax Clearance Certificate"}
              required={true}
              selectedFile={formData?.tax_clearance_certificate}
            />
          </div>

          <div>
            <UploadFileCard
              handleUploadFile={handleFormData}
              inputName={"experience_letter"}
              label={"Upload Experience Letter"}
              required={true}
              selectedFile={formData?.experience_letter}
            />
          </div>

          <div>
            <UploadFileCard
              handleUploadFile={handleFormData}
              inputName={"file1"}
              label={"Upload Legal Doc File"}
              required={true}
              selectedFile={formData?.file1}
            />
          </div>

          <div>
            <UploadFileCard
              handleUploadFile={handleFormData}
              inputName={"file2"}
              label={"Upload Turn Over File"}
              required={true}
              selectedFile={formData?.file2}
            />
          </div>

          <div>
            <UploadFileCard
              handleUploadFile={handleFormData}
              inputName={"file3"}
              label={"Technical File"}
              required={true}
              selectedFile={formData?.file3}
            />
          </div>
          <div>
            <UploadFileCard
              handleUploadFile={handleFormData}
              inputName={"file4"}
              label={"Upload latest audit report"}
              required={true}
              selectedFile={formData?.file4}
            />
          </div>

          <div>
            <UploadFileCard
              handleUploadFile={handleFormData}
              inputName={"file5"}
              label={"Upload financial doc file"}
              required={true}
              selectedFile={formData?.file5}
            />
          </div>

          <div>
            <UploadFileCard
              handleUploadFile={handleFormData}
              inputName={"file6"}
              label={"Upload vendor profile document"}
              required={true}
              selectedFile={formData?.file6}
            />
          </div>

          <div>
            <UploadFileCard
              handleUploadFile={handleFormData}
              inputName={"file7"}
              label={"Upload bid bond document"}
              required={true}
              selectedFile={formData?.file7}
            />
          </div>

          <div>
            <UploadFileCard
              handleUploadFile={handleFormData}
              inputName={"file8"}
              label={"Upload any other file"}
              required={false}
              selectedFile={formData?.file8}
            />
          </div>

          {data?.bid_fee != "0" && (
            <div>
              <UploadFileCard
                fileType=".pdf,.png,.jpg,.jpeg"
                handleUploadFile={handleFormData}
                inputName={"voucher"}
                label={"Upload Voucher"}
                required={false}
                selectedFile={formData?.voucher}
              />
            </div>
          )}
        </div>

        {data?.items_details?.length > 0 && (
          <div>
            <MultiSelectWithCheckboxes
              options={data?.items_details}
              selectedOptions={selectedOptions}
              handleCheckboxChange={(id) => handleCheckboxChange(id)}
            />
          </div>
        )}

        <div className="flex flex-col gap-[5px] ">
          <p className="text-start text-2xl font-bold text-blue-500">Comment</p>
          <textarea
            placeholder="Write comment..."
            onChange={handleFormData}
            name="comment"
            className="w-[95%] md:w-[45%] p-[6px] rounded-[4px] outline-none bg-slate-200 "
          />
        </div>

        {status.error && <p className="text-red-500">*{status.error}</p>}

        <button
          type="submit"
          disabled={status.pending}
          className="w-max px-[20px] py-[8px] text-white rounded-[4px] bg-darkBlue"
        >
          {status.pending ? <Loader /> : "Submit"}
        </button>

        {status.pending && <p>Wait...</p>}
        {status.success && (
          <p className="text-green-500">Document Uploaded Successfully</p>
        )}
      </form>
    </div>
  );
};

export default UploadDocument;
