import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { useUserContext } from "../context/AuthContext";
import { useToastContext } from "../context/ToastContext";
import { useAppliedTendersContext } from "../context/AppliedTendersContext";
import Overlay from "../components/Overlay";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";

const Login = () => {
  const navigate = useNavigate();

  const { setUser, isAuthenticated, setIsAuthenticated, setToken } =
    useUserContext();

  const { handleAppliedTender } = useAppliedTendersContext();

  if (isAuthenticated) {
    navigate("/");
  }

  const { setShowToast, setToastMessage } = useToastContext();
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [isForgotPasswordBtnClicked, setIsForgotPasswordBtnClicked] =
    useState(false);

  const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] =
    useState("");
  const [forgotPasswordSuccessMessage, setForgotPasswordSuccessMessage] =
    useState("");
  const [formData, setFormData] = useState({});
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [forgotPasswordPending, setForgotPasswordPending] = useState(false);
  const [isEyeBtnClicked, setIsEyeBtnClicked] = useState(false);

  const onFormChange = (e) => {
    const { value, name } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // Submit Login form logic
  const submitLoginFormLogic = async (e) => {
    e.preventDefault();

    setLoginErrorMessage("");
    setLoading(true);
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        setForgotPasswordEmail("");
        if (res.status) {
          setShowToast(true);
          setToastMessage("Logged In Successfully.");
          // Store user data in local storage
          localStorage.setItem("user", JSON.stringify(res.users_data));
          localStorage.setItem("token", JSON.stringify(res.token));
          setUser(res.users_data);
          setToken(res.token);
          setIsAuthenticated(true);
          handleAppliedTender(res.applied_tenders_details);
          navigate(`/tender-list`);
        } else {
          setLoginErrorMessage(res.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoginErrorMessage("Something went wrong");
      });
  };

  // Forgot password logic
  const submitForgotPasswordFormLogic = async (e) => {
    e.preventDefault();

    setForgotPasswordErrorMessage("");
    setForgotPasswordSuccessMessage("");

    try {
      setForgotPasswordPending(true);
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/password/email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: forgotPasswordEmail,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setForgotPasswordPending(false);
          if (data.status) {
            setForgotPasswordSuccessMessage(data.message);
          } else {
            setForgotPasswordErrorMessage(data.message);
          }
        });
    } catch (err) {
      setForgotPasswordPending(false);
      setForgotPasswordErrorMessage("Something went wrong!");
      console.log(err);
    }
  };

  return (
    <div className="w-full min-h-[100vh] flex flex-col items-center gap-[30px] px-[20px] py-[30px]">
      <div className="w-full md:w-[50%] lg:w-[500px] flex flex-col items-center bg-slate-300 p-[20px] rounded-[10px]">
        <div>
          <img
            src="./RHEST_Logo.png"
            alt="Rhest_logo"
            className="w-[100px] h-[100px] rounded-full"
          />
        </div>
        <h1 className="text-4xl font-bold text-center">
          Login to your account
        </h1>

        <form
          onSubmit={submitLoginFormLogic}
          className="w-full flex flex-col gap-[20px] mt-[30px]"
        >
          <input
            type="email"
            placeholder="Email"
            name="email"
            required
            value={formData.email || ""}
            onChange={onFormChange}
            className="p-[8px] rounded-[4px] outline-none border border-slate-400 "
          />

          <div className="w-full bg-white flex items-center p-[8px] rounded-[4px] outline-none border border-slate-400">
            <input
              type={isEyeBtnClicked ? "text" : "password"}
              placeholder="password"
              name="password"
              required
              value={formData.password || ""}
              onChange={onFormChange}
              className="flex-grow bg-transparent outline-none"
            />
            <div
              className="w-max cursor-pointer hover:text-darkBlue"
              onClick={() => {
                if (!formData.password) return;
                setIsEyeBtnClicked((prev) => !prev);
              }}
            >
              {isEyeBtnClicked ? <IoEyeOff /> : <IoEye />}
            </div>
          </div>

          <div className="flex items-center justify-end">
            <p
              className="cursor-pointer hover:opacity-[80%] text-blue-500"
              onClick={() => setIsForgotPasswordBtnClicked(true)}
            >
              Forgot Password?
            </p>
          </div>

          {loginErrorMessage && (
            <p className="text-red-500">
              <span className="">Error:</span> {loginErrorMessage}
            </p>
          )}

          <div className="flex items-center gap-[20px] ">
            <button
              type="submit"
              disabled={loading}
              className={`px-[20px] py-[8px] h-[45px] rounded-[4px] bg-darkBlue text-white `}
            >
              {loading ? <Loader /> : "Login"}
            </button>
          </div>
        </form>

        <div className="w-full flex flex-col gap-[10px] mt-[20px]">
          <p className="font-semibold">{"Don't have account?"}</p>
          <Link
            to="/register"
            className="w-max text-blue-500 px-[10px] py-[8px] rounded-[4px] bg-white text-center font-medium"
          >
            Register an Account
          </Link>
        </div>
      </div>

      {isForgotPasswordBtnClicked && (
        <div className="z-30 w-[95%] md:w-[450px] fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] flex flex-col gap-[20px] bg-white p-[20px] pb-[40px] rounded-lg shadow">
          <div className="w-full">
            <div
              onClick={() => setIsForgotPasswordBtnClicked(false)}
              className="cursor-pointer float-right w-[35px] h-[35px] rounded-full border border-slate-800 flex items-center justify-center "
            >
              <RxCross2 className="text-[17px] font-bold" />
            </div>
          </div>
          <h1 className="text-xl font-semibold">Forgot Password?</h1>
          <form
            onSubmit={submitForgotPasswordFormLogic}
            className="flex flex-col gap-[20px]"
          >
            <input
              type="email"
              placeholder="Enter you email"
              name="email"
              required
              value={forgotPasswordEmail || ""}
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
              className="p-[8px] rounded-[4px] outline-none border border-slate-400 "
            />

            <div className="flex items-center gap-[20px] ">
              <button
                type="submit"
                disabled={forgotPasswordPending}
                className={`px-[20px] py-[8px] h-[45px] rounded-[4px] ${
                  forgotPasswordPending ? "bg-darkBlue" : "bg-darkBlue"
                } text-white `}
              >
                {forgotPasswordPending ? <Loader /> : "Submit"}
              </button>
            </div>

            {forgotPasswordSuccessMessage && (
              <p className="text-green-700 font-semibold">
                {forgotPasswordSuccessMessage}
              </p>
            )}
            {forgotPasswordErrorMessage && (
              <p className="text-red-500">
                Error: {forgotPasswordErrorMessage}
              </p>
            )}
          </form>
        </div>
      )}

      {isForgotPasswordBtnClicked && <Overlay />}
    </div>
  );
};

export default Login;
