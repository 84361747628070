import React from "react";
import ProfileSidebar from "../ProfileSidebar";
import { IoIosArrowForward } from "react-icons/io";

const SidebarForMobile = ({
  handleActiveSection,
  setPasswordChangeBtnClick,
  userData,
  isDisplaying,
  setIsDisplaying,
}) => {
  return (
    <div
      className={`lg:hidden z-30 fixed top-0 left-0 ${
        isDisplaying ? "translate-x-[0%]" : "translate-x-[-84%]"
      } w-max h-screen flex items-center transition duration-150 ease-out`}
    >
      <div className="h-full bg-white/75 backdrop-blur-sm">
        <ProfileSidebar
          handleActiveSection={handleActiveSection}
          setPasswordChangeBtnClick={setPasswordChangeBtnClick}
          userData={userData}
        />
      </div>

      <div className="mt-[100px] h-[95vh] rounded-r-[5px] bg-slate-200/75 backdrop-blur-sm flex items-center px-[10px]">
        <div
          onClick={setIsDisplaying}
          className={`w-[40px] h-[40px] rounded-full bg-white flex items-center justify-center ${isDisplaying ? 'rotate-180' : 'rotate-0'} transition duration-200 ease-in`}
        >
          <IoIosArrowForward />
        </div>
      </div>
    </div>
  );
};

export default SidebarForMobile;
