import React, { useState } from "react";
import InputWithLabel from "../Elements/InputWithLabel";
import FileInputWithLabel from "../Elements/FileInputWithLabel";
import CountryDropdown from "../Elements/CountryDropdown";

const CompanyDetailsForm = ({
  countryData,
  handleFormChange,
  handleFileSelect,
  formData,
}) => {
  return (
    <div className="w-[98%] md:w-[75%]">
      <h2 className="font-semibold text-2xl pb-[30px] text-darkBlue underline underline-offset-8 decoration-darkBlue">
        Company Details:
      </h2>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[12px] lg:gap-[30px]">
        <InputWithLabel
          label="Company Name"
          inputPlaceholder="e.g. ABC Pvt. Ltd."
          name="bidder_name"
          value={formData?.bidder_name}
          handleInputChange={handleFormChange}
          required={true}
          type="text"
        />
        <CountryDropdown
          data={countryData}
          name="country_id"
          required={true}
          value={formData?.country_id}
          handleSelectChange={handleFormChange}
        />
        <InputWithLabel
          label="Address"
          inputPlaceholder="Enter street address"
          required={true}
          type="text"
          name="address"
          value={formData?.address}
          handleInputChange={handleFormChange}
        />
        <InputWithLabel
          label="City"
          inputPlaceholder="Enter City Name"
          required={true}
          type="text"
          name="city"
          value={formData?.city}
          handleInputChange={handleFormChange}
        />
        <InputWithLabel
          label="Website"
          inputPlaceholder="Company Website URL"
          type="text"
          name="website"
          value={formData?.website}
          handleInputChange={handleFormChange}
        />
        <InputWithLabel
          label="Company Registration Number"
          inputPlaceholder="Enter Company Registration Number"
          required={true}
          type="text"
          name="company_reg_no_input"
          value={formData?.company_reg_no_input}
          handleInputChange={handleFormChange}
        />
        <InputWithLabel
          label="VAT/PAN Number"
          inputPlaceholder="Enter VAT/PAN number"
          required={true}
          type="number"
          name="vat_no_input"
          value={formData?.vat_no_input}
          handleInputChange={handleFormChange}
        />
        <FileInputWithLabel
          label="Upload Company Logo"
          name="company_logo"
          handleFileSelect={handleFileSelect}
        />
        <FileInputWithLabel
          required={true}
          label="Upload Company Registration Document"
          name="registration_no"
          handleFileSelect={handleFileSelect}
        />

        <FileInputWithLabel
          required={true}
          label="Upload VAT/PAN Certificate"
          name="vat_no_or_pan_no"
          handleFileSelect={handleFileSelect}
        />

        <FileInputWithLabel
          required={true}
          label="Upload Tax Clearance Document"
          name="tax_clearance"
          handleFileSelect={handleFileSelect}
        />
      </div>
    </div>
  );
};

export default CompanyDetailsForm;
