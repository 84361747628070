import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useUserContext } from "../../context/AuthContext";
import Loader from "../Loader";
import { useToastContext } from "../../context/ToastContext";

const EditContactPerson = ({ handleCross, data, handleSuccess }) => {
  const { token } = useUserContext();
  const { setShowToast, setToastMessage } = useToastContext();

  const { f_name, l_name, mobile, position, telephone1, email } = data;

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    f_name,
    l_name,
    mobile,
    position,
    telephone1,
    contact_email: email,
  });

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      setMessage("");
      setError(false);
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/update-contact-person`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(false);
            setShowToast(true);
            setToastMessage("Updated Successfully.");
            handleSuccess();
          } else {
            setError(true);
            setMessage(data.message);
          }
        });
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err.message);
    }
  };

  return (
    <div className="z-30 w-screen md:w-[75%] h-[90%] overflow-y-auto fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] flex flex-col gap-[20px] bg-white p-[20px] pb-[40px] rounded-lg shadow">
      <div className="w-full">
        <div
          onClick={handleCross}
          className="cursor-pointer float-right w-[35px] h-[35px] rounded-full border border-slate-800 flex items-center justify-center "
        >
          <RxCross2 className="text-[17px] font-bold" />
        </div>
      </div>

      <div className="w-full">
        <p className="text-blue-600 font-semibold text-xl">
          Edit Contact Person
        </p>
        <form onSubmit={handleSubmit} className="w-full flex flex-col space-y-[20px]">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[20px] mt-[30px] ">
            <div className="flex flex-col space-y-[10px]">
              <label className="text-slate-900 text-lg font-semibold">
                First Name
              </label>
              <input
                placeholder="Enter first name"
                name="f_name"
                type="text"
                value={formData.f_name}
                onChange={handleOnchange}
                className="w-full outline-none bg-slate-200 rounded-[4px] p-[8px]"
              />
            </div>

            <div className="flex flex-col space-y-[10px]">
              <label className="text-slate-900 text-lg font-semibold">
                Last Name
              </label>
              <input
                placeholder="Enter last name"
                name="l_name"
                type="text"
                value={formData.l_name}
                onChange={handleOnchange}
                className="outline-none bg-slate-200 rounded-[4px] p-[8px]"
              />
            </div>

            <div className="flex flex-col space-y-[10px]">
              <label className="text-slate-900 text-lg font-semibold">
                Position
              </label>
              <input
                placeholder="Your position"
                name="position"
                type="text"
                value={formData.position}
                onChange={handleOnchange}
                className="outline-none bg-slate-200 rounded-[4px] p-[8px]"
              />
            </div>

            <div className="flex flex-col space-y-[10px]">
              <label className="text-slate-900 text-lg font-semibold">
                Mobile Number
              </label>
              <input
                placeholder="Your mobile no."
                name="mobile"
                type="number"
                value={formData.mobile}
                onChange={handleOnchange}
                className="outline-none bg-slate-200 rounded-[4px] p-[8px]"
              />
            </div>

            <div className="flex flex-col space-y-[10px]">
              <label className="text-slate-900 text-lg font-semibold">
                Telephone1
              </label>
              <input
                placeholder="Telephone 1"
                name="telephone1"
                type="number"
                value={formData.telephone1}
                onChange={handleOnchange}
                className="outline-none bg-slate-200 rounded-[4px] p-[8px]"
              />
            </div>

            <div className="flex flex-col space-y-[10px]">
              <label className="text-slate-900 text-lg font-semibold">
                Email
              </label>
              <input
                placeholder="your email id"
                name="contact_email"
                type="email"
                value={formData.contact_email}
                onChange={handleOnchange}
                className="outline-none bg-slate-200 rounded-[4px] p-[8px]"
              />
            </div>
          </div>

          {error && message && (
            <p className="text-red-600 font-semibold">* {message}</p>
          )}
          <div>
            <button
              disabled={loading}
              className="bg-darkBlue text-white px-[15px] py-[8px] rounded-[4px] "
            >
              {loading ? <Loader /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditContactPerson;
