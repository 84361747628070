import React from "react";
import {
  BsInstagram,
  BsFacebook,
  BsTwitter,
  BsYoutube,
  BsArrowRightShort,
} from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {

  const handleSubscription = async()=>{
    
  }
  return (
    <div className="w-full mt-[100px] flex flex-col items-center gap-[30px]">
      {/* <div className="bg-darkBlue w-full flex items-start lg:items-center flex-wrap px-[80px] text-white">
        <div className="w-full lg:w-[60%] border-r-2 border-r-white text-[25px] font-medium flex flex-col gap-[20px] py-[40px]">
          <h1>FOLLOW #RHEST</h1>
          <div className="flex items-center gap-[20px] text-white">
            <BsInstagram />
            <BsFacebook />
            <BsTwitter />
            <BsYoutube />
          </div>
        </div>
        <div className="w-full lg:w-[40%] text-[25px] font-medium flex flex-col gap-[20px] lg:pl-[70px]">
          <h1>RESOURCES</h1>
          <div>
            <form className="w-full flex items-center justify-between border-b border-b-white">
              <input
                type="email"
                placeholder="PLEASE ENTER YOUR EMAIL ADDRESS"
                className="bg-transparent text-[17px] outline-none placeholder:text-white"
              />
              <BsArrowRightShort />
            </form>
            <p className="text-[13px] mt-[20px]">Read Terms and Conditions</p>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center px-[20px] lg:px-[80px]">
        <div className="flex flex-col gap-[20px]">
          <div className="flex items-center">
            <h1 className="pr-[10px] lg:pr-[50px] border-r border-r-black font-bold text-lg">
              OUR WORK
            </h1>
            <ul className="flex flex-col lg:flex-row items-center lg:gap-[40px] pl-[10px] lg:pl-[50px] font-bold text-gray-600 ">
              <li>Stop Girl Trafficking</li>
              <li>In Honour Of AMAR</li>
              <li>Health Care</li>
            </ul>
          </div>
          <div className="flex items-center">
            <h1 className="pr-[10px] lg:pr-[50px] border-r border-r-black font-bold text-lg">
              ABOUT US
            </h1>
            <ul className="flex flex-col lg:flex-row items-center lg:gap-[40px] pl-[10px] lg:pl-[50px] font-bold text-gray-600 ">
              <li>Our Founder</li>
              <li> Executive Committee</li>
              <li>Staff</li>
              <li>Partners</li>
            </ul>
          </div>
          <div className="flex items-center">
            <h1 className="pr-[10px] lg:pr-[50px] border-r border-r-black font-bold text-lg">
              GET INVOLVED
            </h1>
            <ul className="flex flex-col lg:flex-row items-center lg:gap-[40px] pl-[10px] lg:pl-[50px] font-bold text-gray-600 ">
              <li>Stories</li>
              <li> Work With Us</li>
              <li> Contact Us</li>
              <li> Blog</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-[84%] h-[1px] bg-gray-500 mt-[10px]" />

      <div className="w-[85%] flex items-center justify-center flex-wrap gap-[30px] lg:gap-[100px]">
        <div className="flex flex-col items-center gap-[10px] text-center text-gray-700">
          <MdEmail className="text-[33px]" />
          <p className="font-semibold">Email us at</p>
          <p>info@rhest.org</p>
        </div>
        <div className="flex flex-col items-center gap-[10px] text-center text-gray-700">
          <IoCall className="text-[33px]" />
          <p className="font-semibold">Call us at</p>
          <p>+977 014537371</p>
        </div>
        <div className="flex flex-col items-center gap-[10px] text-center text-gray-700">
          <FaLocationDot className="text-[33px]" />
          <p className="font-semibold">Find us at</p>
          <p>Bishalnagar-5, Kathmandu, Nepal</p>
        </div>
      </div>

      <div className="w-[84%] h-[1px] bg-gray-500 mt-[10px]" />

      <p className="text-sky-500 font-bold text-lg px-[20px]">
        Photographs by Luigi Fieni, Gaurav Tamang, Bruce Moore and Janak Thapa
        courtesy of the American Himalayan Foundation.
      </p> */}

      <div className="w-full bg-darkBlue p-[10px] text-white text-center text-[14px] font-medium tracking-wider">
        Copyright @ 2024 RHEST. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
