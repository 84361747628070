
// Get tender categories with tender list
export const getTendersData = async () => {
  const data = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/categories`
  )
    .then(async (res) => await res.json())
    .then((res) => res);

  return data.categories;
};

// Get tender list
export const getTenderList = async () => {
  const data = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/tenders`
  )
    .then((res) => res.json())
    .then((res) => res);
  return data.tender;
};

// Get Country List
export const getCountryList = async () => {
  const data = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/countries`
  )
    .then((res) => res.json())
    .then((res) => res);
  return data.countries;
};

// Get Bidder profile
export const getBidderProfile = async (token) => {
  const data = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/bidder-profile`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((res) => res);
  return data.data;
};

// Apply directly
export const applyDirectly = async (token, tenderId) => {
  try {
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1/apply-directly/${tenderId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  } catch (error) {
    console.log(error);
    return {
      message: "something went wrong",
    };
  }
};
