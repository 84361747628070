import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { useUserContext } from "../context/AuthContext";
import GeneralPopup from "../components/Popup/GeneralPopup";
import Overlay from "../components/Overlay";
import { IoEye, IoEyeOff } from "react-icons/io5";

const Register = () => {
  const navigate = useNavigate();

  const { isAuthenticated } = useUserContext();

  if (isAuthenticated) {
    navigate("/tender-list");
  }

  const [isEyeBtn1Clicked, setIsEyeBtn1Clicked] = useState(false);
  const [isEyeBtn2Clicked, setIsEyeBtn2Clicked] = useState(false);
  const [clickedEyeId, setClickedEyeId] = useState(null);
  const [formData, setFormData] = useState({});
  const [registerErrorMessage, setRegisterErrorMessage] = useState("");
  const [registeredMessage, setRegisteredMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onFormChange = (e) => {
    const { value, name } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // Submit Register form
  const submitRegisterFormLogic = async (e) => {
    e.preventDefault();

    setRegisterErrorMessage("");
    setRegisteredMessage("");
    if (formData.password !== formData.confirmPassword) {
      setRegisterErrorMessage("Password and confirm password did not match");
      return;
    }

    try {
      setLoading(true);
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.userName,
            email: formData.email,
            password: formData.password,
          }),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          if (data.status) {
            setRegisteredMessage(data.message);
            return data;
          } else {
            if (data.errors) {
              const errorMessages = Object.keys(data.errors).map((key) => data.errors[key][0]);
              setRegisterErrorMessage(errorMessages.join(', '));
            } else {
              setRegisterErrorMessage(data.message);
            }
          }
        })
        .catch((err) => {
          setRegisteredMessage("Something went wrong!");
        });
    } catch (err) {
      console.error("Error during registration:", err);
      setRegisterErrorMessage("Something went wrong");
    }
  };

  return (
    <div className="w-full md:w-[50%] lg:w-[500px] min-h-[100vh] flex flex-col items-center gap-[30px] px-[20px] py-[30px]">
      <div className="w-full flex flex-col items-center bg-slate-300 p-[20px] rounded-[10px]">
        <div>
          <img
            src="./RHEST_Logo.png"
            alt="Rhest_logo"
            className="w-[100px] h-[100px] rounded-full"
          />
        </div>
        <h1 className="text-4xl font-bold text-center">
          Register your account
        </h1>

        <form
          onSubmit={submitRegisterFormLogic}
          className="w-full flex flex-col gap-[20px] mt-[30px]"
        >
          <input
            type="text"
            placeholder="Username"
            name="userName"
            required
            value={formData.userName || ""}
            onChange={onFormChange}
            className="p-[8px] rounded-[4px] outline-none border border-slate-400 "
          />
          <input
            type="email"
            placeholder="Email ID"
            name="email"
            required
            value={formData.email || ""}
            onChange={onFormChange}
            className="p-[8px] rounded-[4px] outline-none border border-slate-400 "
          />
          <div className="bg-white w-full flex items-center justify-between p-[8px] rounded-[4px] outline-none border border-slate-400 ">
            <input
              type={
                clickedEyeId === 1 && isEyeBtn1Clicked ? "text" : "password"
              }
              placeholder="Password"
              name="password"
              required
              value={formData.password || ""}
              onChange={onFormChange}
              className="flex-grow bg-transparent outline-none"
            />
            <div
              className="w-max cursor-pointer hover:text-darkBlue"
              onClick={() => {
                if (!formData.password) return;
                setClickedEyeId(1);
                setIsEyeBtn1Clicked((prev) => !prev);
              }}
            >
              {clickedEyeId === 1 && isEyeBtn1Clicked ? (
                <IoEyeOff />
              ) : (
                <IoEye />
              )}
            </div>
          </div>

          <div className="bg-white w-full flex items-center justify-between p-[8px] rounded-[4px] outline-none border border-slate-400 ">
            <input
              type={
                clickedEyeId === 2 && isEyeBtn2Clicked ? "text" : "password"
              }
              placeholder="Confirm Password"
              name="confirmPassword"
              required
              value={formData.confirmPassword || ""}
              onChange={onFormChange}
              className="flex-grow bg-transparent outline-none"
            />
            <div
              className="w-max cursor-pointer hover:text-darkBlue"
              onClick={() => {
                if (!formData.confirmPassword) return;
                setClickedEyeId(2);
                setIsEyeBtn2Clicked((prev) => !prev);
              }}
            >
              {clickedEyeId === 2 && isEyeBtn2Clicked ? (
                <IoEyeOff />
              ) : (
                <IoEye />
              )}
            </div>
          </div>
          {registerErrorMessage && (
            <p className="text-red-500">Error: {registerErrorMessage}</p>
          )}
          <div>
            <button
              type="submit"
              disabled={loading}
              className={`px-[20px] py-[8px] rounded-[4px] ${
                loading ? "bg-darkBlue" : "bg-darkBlue"
              } text-white `}
            >
              {loading ? <Loader /> : " Sign Up"}
            </button>
          </div>

          {registeredMessage && (
            <p className="text-blue-500">{registeredMessage}</p>
          )}
        </form>

        <div className="w-full flex flex-col gap-[10px] mt-[20px]">
          <p className="font-semibold">{"Already have account?"}</p>
          <Link
            to="/login"
            className="w-max text-blue-500 px-[10px] py-[8px] rounded-[4px] bg-white text-center font-medium"
          >
            Login
          </Link>
        </div>
      </div>

      {registeredMessage && (
        <GeneralPopup
          handleCross={() => setRegisteredMessage("")}
          text={registeredMessage}
        />
      )}
      {registeredMessage && <Overlay />}
    </div>
  );
};

export default Register;
