import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserContext } from "../context/AuthContext";
import Loader from "../components/Loader";
import Table from "../components/Elements/Table";
import TenderFile from "../components/Tenders/TenderFile";
import UploadDocument from "../components/Bidder/UploadDocument";
import Overlay from "../components/Overlay";
import { dateTimeFormatter } from "../utils/dateTimeFormatter";

const TenderDetails = () => {
  const { slug } = useParams();
  const { user, token } = useUserContext();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [isAlreadyApplied, setIsAlreadyApplied] = useState(false);
  const [isApplyBidBtnClicked, setIsApplyBidBtnClicked] = useState(false);
  const [canNotSubmit, setCanNotSubmit] = useState(false);

  //Check submission date
  useEffect(() => {
    if (data?.submission_date) {
      const submissionDate = new Date(data.submission_date);
      if (Date.now() > submissionDate) {
        setCanNotSubmit(true)
      } else {
        setCanNotSubmit(false)
      }
    }
  }, [data])

  useEffect(() => {
    function AlreadyApplied() {
      const a = data?.applied_bidders.some((obj) => {
        return obj.user_id.toString() === (user?.id).toString();
      });
      setIsAlreadyApplied(a);
    }

    AlreadyApplied();
  }, [data]);

  const fetchTenderDetails = async () => {
    setLoading(true);
    try {
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/tender/${slug}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.data) {
            setData(data.data);
          }
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchTenderDetails();
  }, []);

  return (
    <div className="w-full min-h-[100vh] flex flex-col items-center ">
      {loading && (
        <div className="w-full h-[70vh] flex items-center justify-center">
          <Loader />
        </div>
      )}
      {!loading && !data && (
        <div className="w-full h-[70vh] flex justify-center items-center">
          <p className="text-center font-bold">No Data Found</p>
        </div>
      )}
      {!loading && data && (
        <div className="w-full md:w-[80%] flex flex-col items-center space-y-[30px] p-[20px]">
          <h2 className="text-2xl font-bold text-center">
            {data?.tender_name}
          </h2>
          {(!canNotSubmit && isAlreadyApplied) && (
            <p className="bg-blue-600 px-[15px] py-[8px] rounded-[25px] text-white ">
              Already Applied
            </p>
          )}
          {(canNotSubmit) && (
            <p className="bg-blue-600 px-[15px] py-[8px] rounded-[25px] text-white ">
              Submission time is over
            </p>
          )}
          <div dangerouslySetInnerHTML={{ __html: data?.description }} />

          <Table
            data={{
              "Bid fee": `Rs.${data?.bid_fee}`,
              "Opening Date": dateTimeFormatter(data?.opening_date),
              "Published Date": dateTimeFormatter(data?.published_date),
              "Purchase Date": dateTimeFormatter(data?.purchase_date),
              "Submission Date": dateTimeFormatter(data?.submission_date),
            }}
          />
          {data?.items_details?.length > 0 && (
            <div className="w-full flex flex-col space-y-[20px]">
              <p className="text-start text-2xl font-bold text-blue-500">
                Items Detail
              </p>
              <table className="w-full border border-blue-500">
                <thead className="w-full">
                  <tr className="w-full">
                    <td className="w-[40%] text-slate-700 font-bold">
                      Items Name
                    </td>
                    <td className="w-[60%] text-slate-700 font-bold">
                      Is Compulsory?
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {data.items_details.map((item, index) => (
                    <tr key={index}>
                      <td className="font-semibold text-darkBlue">
                        {item.item_name}
                      </td>
                      <td className="font-semibold">
                        {item.item_is_compulsory}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="w-full flex flex-col space-y-[20px]">
            <p className="text-start text-2xl font-bold text-blue-500">
              Download Tender Files
            </p>
            <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-[30px]">
              {data.technical_doc_file && (
                <div className="w-full">
                  <TenderFile
                    documentUrl={data.technical_doc_file}
                    name="Technical Doc File"
                  />
                </div>
              )}
              {data.financial_doc_file && (
                <div className="w-full">
                  <TenderFile
                    documentUrl={data.financial_doc_file}
                    name="Financial Doc File"
                  />
                </div>
              )}
              {data.tender_doc_file && (
                <div className="w-full">
                  <TenderFile
                    documentUrl={data.tender_doc_file}
                    name="Bid Document"
                  />
                </div>
              )}

              {data.other_related_doc1 && (
                <div className="w-full">
                  <TenderFile
                    documentUrl={data.other_related_doc1}
                    name="Other Related Doc1"
                  />
                </div>
              )}
              {data.other_related_doc2 && (
                <div className="w-full">
                  <TenderFile
                    documentUrl={data.other_related_doc2}
                    name="Other Related Doc2"
                  />
                </div>
              )}
            </div>
          </div>

          {
            !canNotSubmit && (
              <div className="w-full flex flex-start">
                {(!isAlreadyApplied) && (
                  <div className="w-full">
                    <button
                      onClick={() => {
                        setIsApplyBidBtnClicked(true);
                      }}
                      className="bg-darkBlue px-[13px] py-[8px] text-white rounded-[25px] "
                    >
                      Apply Bid
                    </button>
                  </div>
                )}
              </div>
            )
          }
        </div>
      )}

      {isApplyBidBtnClicked && (
        <UploadDocument
          data={data}
          handleCross={() => setIsApplyBidBtnClicked(false)}
        />
      )}
      {isApplyBidBtnClicked && <Overlay />}
    </div>
  );
};

export default TenderDetails;
