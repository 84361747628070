export const navLinks =  [
    {
      title: "Home",
      link: "/",
      subCategory: [],
    },
    {
      title: "Tender Lists",
      link: "/tender-list",
      subCategory: [],
    },
  ];