import React from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { BsInfoCircleFill } from "react-icons/bs";

const GeneralPopup = ({ text, btnName, btnLink, handleCross }) => {
  return (
    <div className="z-30 w-[95%] md:w-[450px] fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] flex flex-col gap-[20px] bg-white p-[20px] pb-[40px] rounded-lg shadow">
      <div className="w-full">
        <div
          onClick={handleCross}
          className="cursor-pointer float-right w-[35px] h-[35px] rounded-full border border-slate-800 flex items-center justify-center "
        >
          <RxCross2 className="text-[17px] font-bold" />
        </div>
      </div>

      <div className="w-full flex flex-col items-center gap-[20px]">

          <BsInfoCircleFill className="text-blue-500 text-2xl" />
 
        <p className="text-lg font-semibold text-center">{text}</p>

        {btnLink && (
          <Link
            to={btnLink}
            className="text-white bg-darkBlue px-[20px] py-[8px] rounded-[4px] "
          >
            {btnName}
          </Link>
        )}
      </div>
    </div>
  );
};

export default GeneralPopup;
