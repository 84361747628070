import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Loader from "../Loader";
import { Link, useNavigate } from "react-router-dom";
import { useToastContext } from "../../context/ToastContext";

const ApplyDirectly = ({ handleCross, tenderDetails, token, user }) => {
  const navigate = useNavigate();

  const { setShowToast, setToastMessage } = useToastContext();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [message, setMessage] = useState("");

  const handleApplyBtnClick = async () => {
    setIsPending(true);
    setIsSuccess(false);
    setMessage("");
    try {
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/apply-directly/${tenderDetails?.tenderId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            setIsPending(false);
            setIsSuccess(true);
            setMessage(data.message);
            setShowToast(true);
            setToastMessage("Applied Successfully");
            navigate(`/profile/${user?.id}`);
          } else {
            setIsPending(false);
            setIsSuccess(false);
            setMessage(data.message);
          }
        });
    } catch (err) {
      console.log(err);
      setIsPending(false);
      setIsSuccess(false);
      setMessage("something went wrong!");
    }
  };

  return (
    <div className="z-30 w-[96%] md:w-[450px] fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] flex flex-col gap-[20px] bg-white p-[20px] pb-[40px] rounded-lg shadow">
      <div className="w-full">
        <div
          onClick={handleCross}
          className="cursor-pointer float-right w-[35px] h-[35px] rounded-full border border-slate-800 flex items-center justify-center "
        >
          <RxCross2 className="text-[17px] font-bold" />
        </div>
      </div>

      <div className="w-full flex flex-col items-center">
        <h2 className="text-xl font-semibold">You don't have to pay to apply for this tender.</h2>

        <div>
          <button
            className="bg-darkBlue px-[20px] py-[8px] rounded-[4px] text-white mt-[10px] "
            onClick={handleApplyBtnClick}
            disabled={isPending}
          >
            {isPending ? <Loader /> : "Apply"}
          </button>
        </div>
      </div>

      {!isSuccess && message && <p className="text-red-700 text-center">* {message}</p>}

      {isSuccess && message && (
        <div>
          <p className="text-green-700">{message}</p>
          <Link
            to={`/profile/${user?.id}`}
            className="text-darkBlue text-lg mt-[10px] underline underline-offset-8 decoration-darkBlue"
          >
            View Details
          </Link>
        </div>
      )}
    </div>
  );
};

export default ApplyDirectly;
