import React, { useState } from "react";

const CountryDropdown = ({ data,required,name,value,handleSelectChange }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleSelectClick = () => {
    // Toggle the state to show/hide the country list
    setIsSelectOpen(!isSelectOpen);
  };

  return (
    <div className="w-full flex flex-col gap-[5px]">
      <label htmlFor="country" className="font-semibold">
        Select Country:
      </label>
      <div style={{ position: "relative" }} className="w-full">
        <select
          name={name}
          id="country"
          required={required}
          value={value || ""} // Set the selected value
          onChange={handleSelectChange} // Handle change event
          onClick={handleSelectClick}
          onBlur={() => setIsSelectOpen(false)}
          className="w-full bg-slate-200 px-[20px] py-[8px] rounded-[4px]"
        >
          {/* Default option with an empty value */}
          <option value="" disabled hidden>
            Select a country
          </option>
          {data?.length > 0 &&
            data.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default CountryDropdown;
