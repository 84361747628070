import React from "react";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

const DepositPopup = ({ handleCross, tenderDetails }) => {
  const navigate = useNavigate();

  const handleUploadBtnClick = () => {
    navigate(
      `/upload-voucher?tender=${encodeURIComponent(
        JSON.stringify(tenderDetails)
      )}`
    );
  };

  return (
    <div className="z-30 w-[96%] md:w-[450px] fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] flex flex-col gap-[20px] bg-white p-[20px] pb-[40px] rounded-lg shadow">
      <div className="w-full">
        <div
          onClick={handleCross}
          className="cursor-pointer float-right w-[35px] h-[35px] rounded-full border border-slate-800 flex items-center justify-center "
        >
          <RxCross2 className="text-[17px] font-bold" />
        </div>
      </div>

      <div>
        <h1 className="text-xl font-semibold tracking-wider">
          <span className="text-red-500">*</span> Deposit{" "}
          <span className="text-darkBlue font-bold">
            Rs. {tenderDetails?.bid_fee}
          </span>{" "}
          to the following bank account to access this tender.
        </h1>
        {tenderDetails?.bank_details?.length > 0 &&
          tenderDetails?.bank_details?.map((item, index) => (
            <div key={index} className="mt-[20px] w-full border border-slate-500 p-[10px]">
              <p>
                <span className="text-darkBlue font-semibold">
                  {item.bank_name}
                </span>
              </p>
              <p>
                <span className="text-darkBlue font-semibold">
                  {item.bank_account_no}
                </span>
              </p>
            </div>
          ))}
      </div>

      <div>
        <h3 className="text-lg font-semibold">Already Deposited?</h3>
        <button
          className="bg-darkBlue px-[20px] py-[8px] rounded-[4px] text-white mt-[10px] "
          onClick={handleUploadBtnClick}
        >
          Upload Paid Voucher
        </button>
      </div>
    </div>
  );
};

export default DepositPopup;
