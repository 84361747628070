import React from "react";

const TermsAndCondition = () => {
  return (
    <div className="w-full min-h-[100vh] flex flex-col items-center px-[20px]">
      <h1>Terms And Condition</h1>
    </div>
  );
};

export default TermsAndCondition;
