import React, { useState } from "react";
import { useUserContext } from "../../context/AuthContext";
import { dateTimeFormatter } from "../../utils/dateTimeFormatter";
import { useAppliedTendersContext } from "../../context/AppliedTendersContext";
import { useNavigate } from "react-router-dom";

const TendersTable = ({ data, isActive }) => {
  const navigate = useNavigate();

  const { appliedTendersId } = useAppliedTendersContext();

  return (
    <div
      className={`transform ${isActive && "animateTable"
        } translate-y-[0%] opacity-100 transition duration-150 ease-out rounded-lg overflow-hidden shadow-md shadow-slate-400/75`}
    >
      {data?.length === 0 && (
        <div>
          <p className="text-lg font-bold">No tenders Found</p>
        </div>
      )}
      {data?.length > 0 && (
        <table className="w-full overflow-auto rounded-[10px] ">
          <thead className="bg-slate-300">
            <tr>
              <th>S.N.</th>
              <th>Tender Name</th>
              <th>Notice Published Date</th>
              <th>Last Date of Bid Purchase</th>
              <th>Last date of Bid Submission</th>
              <th>Tender Opening Date</th>
              <th className="bg-darkBlue text-white">Bid Now</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 &&
              data.map(
                (
                  {
                    id,
                    tender_name,
                    published_date,
                    purchase_date,
                    submission_date,
                    opening_date,
                    bid_fee,
                    applied_bidders,
                  },
                  index
                ) => (
                  <tr
                    key={index}
                    className={`${appliedTendersId.includes(id) && "bg-slate-100"
                      }`}
                    onClick={() => navigate(`/tender-details/${id}`)}
                  >
                    <td>{index + 1}.</td>
                    <td className="font-semibold text-gray-700">
                      {tender_name}
                    </td>
                    <td>{dateTimeFormatter(published_date)}</td>
                    <td>{dateTimeFormatter(purchase_date)}</td>
                    <td>{dateTimeFormatter(submission_date)}</td>
                    <td>{dateTimeFormatter(opening_date)}</td>
                    <td
                      className={`hover:bg-darkBlue whitespace-nowrap cursor-pointer hover:text-white underline underline-offset-4 decoration-darkBlue`}
                    >
                      View Bid
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TendersTable;
