import React, { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import Tooltip from "../Elements/Tooltip";
import EditContactPerson from "../Forms/EditContactPerson";
import Overlay from "../Overlay";

const CompanyContactPerson = ({ data, handleEditSuccess }) => {
  const [isEditBtnClicked, setIsEditBtnClicked] = useState(false);

  return (
    <div className="w-full">
      <div className="flex items-center gap-[20px]">
        <h1 className="text-start text-2xl font-bold text-blue-500">
          Company Contact Person
        </h1>
        <Tooltip text="Edit contact person">
          <div
            onClick={() => {
              setIsEditBtnClicked(true);
            }}
            className="cursor-pointer hover:bg-gray-300 hover:text-black text-xl bg-darkBlue text-white w-[40px] h-[40px] rounded-full flex items-center justify-center text-white "
          >
            <AiFillEdit />
          </div>
        </Tooltip>
      </div>

      <table className="w-full relative mt-[20px]">
        <tbody>
          <tr>
            <td className="font-semibold text-slate-700">Name</td>
            <td>
              {data.f_name} {data.l_name}
            </td>
          </tr>
          <tr>
            <td className="font-semibold text-slate-700">position</td>
            <td>{data.position}</td>
          </tr>
          <tr>
            <td className="font-semibold text-slate-700">Mobile</td>
            <td>{data.mobile}</td>
          </tr>
          <tr>
            <td className="font-semibold text-slate-700">Telephone</td>
            <td>{data.telephone1}</td>
          </tr>
          <tr>
            <td className="font-semibold text-slate-700">Email</td>
            <td>{data.contact_email}</td>
          </tr>
        </tbody>
      </table>

      <div className="w-full">
        {isEditBtnClicked && (
          <EditContactPerson
            handleCross={() => setIsEditBtnClicked(false)}
            data={data}
            handleSuccess={() => {
              setIsEditBtnClicked(false);
              handleEditSuccess();
            }}
          />
        )}
      </div>

      {isEditBtnClicked && <Overlay />}
    </div>
  );
};

export default CompanyContactPerson;
