import React, { useState } from "react";

const MultiSelectWithCheckboxes = ({
  options,
  handleCheckboxChange,
  selectedOptions,
}) => {
  return (
    <div className="w-full flex flex-col">
      <div className="w-max">
        <label className="text-start text-2xl font-bold text-blue-500">
         Select the items you can provide
        </label>
        <div className=" flex flex-col gap-[5px] items-start mt-[10px]">
          {options.map((option) => (
            <div
              key={option.item_id}
              className="flex items-center gap-[5px] text-lg"
            >
              <input
                type="checkbox"
                id={option.item_id}
                checked={selectedOptions.includes(option.item_id)}
                onChange={() => handleCheckboxChange(option.item_id)}
              />
              <label htmlFor={option.item_id} className="font-semibold cursor-pointer">
                {option.item_name}{" "}
                {option.item_is_compulsory == "yes" && (
                  <span className="text-red-600">*</span>
                )}
              </label>
            </div>
          ))}

          <p className="w-max bg-gray-200 p-[9px] mt-[20px]">
            Items with <span className="text-red-600">*</span> is compulsory to supply for this bid .
          </p>
        </div>
      </div>
    </div>
  );
};

export default MultiSelectWithCheckboxes;
