import { useMutation, useQuery } from "@tanstack/react-query";
import {
  changePassword,
  createUserAccount,
  loginUserAccount,
} from "../../query/postQuery";
import { getTenderList } from "../../query/getQuery";
import axios from "axios";

export const useCreateUserAccount = () => {
  return useMutation({
    mutationFn: async (user) => {
      const res = await createUserAccount(user);
      return res;
    },
  });
};

export const useLoginUserAccount = () => {
  return useMutation({
    mutationFn: async (user) => {
      const res = await loginUserAccount(user);
      return res;
    },
  });
};

export const usePasswordChange = () => {
  return useMutation({
    mutationFn: async (info) => {
      const data = {
        old_password: info.old_password,
        new_password: info.new_password,
        confirm_password: info.confirm_password,
      };
      const res = await changePassword(data, info.token);
      return res;
    },
  });
};

export const useUploadVoucher = () => {
  return useMutation({
    mutationFn: async (info) => {
      try {
        const formData = new FormData();
        formData.append("voucher", info.file);

        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/bidder-file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${info?.token}`,
            },
          }
        );

        return res;
      } catch (error) {
        console.error("Error uploading voucher:", error);
        throw error;
      }
    },
  });
};
