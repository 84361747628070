import React, { useEffect, useState } from "react";
import { BsFiletypePdf } from "react-icons/bs";
import { RiFolderDownloadLine } from "react-icons/ri";

const TenderFile = ({ documentUrl, name }) => {
  return (
    <div className="w-full h-[250px] bg-slate-300 flex flex-col items-center justify-evenly space-y-[20px] p-[20px] rounded-[4px]">
      <h2>{name}</h2>

      <BsFiletypePdf className="text-[50px] " />
      <a href={documentUrl} target="_blank" download>
        <button className="w-full px-[20px] py-[8px] bg-red-600 rounded-[5px] flex items-center justify-center gap-[5px] text-white ">
          <RiFolderDownloadLine />
          <p className="hidden lg:block bg-transparent"> Download</p>
        </button>
      </a>
    </div>
  );
};

export default TenderFile;
