import React, { useState } from "react";

const UploadFileCard = ({ handleUploadFile, label, inputName, required, selectedFile, fileType }) => {

  return (
    <div className="w-full h-[250px] p-[12px] rounded-[10px] flex flex-col justify-between items-center gap-[10px] bg-white border border-slate-300 shadow-md shadow-slate-400">
      <h3 className="text-[13px] md:text-[15px] font-semibold text-center text-slate-700">{label}</h3>
      <div>
        {selectedFile ? (
          <iframe
            title={`Tax clearance`}
            src={URL.createObjectURL(selectedFile)}
            className="w-full h-[100px] md:h-[130px]"
          ></iframe>
        ) : (
          <p className="text-slate-400 font-semibold">Upload PDF File</p>
        )}
      </div>

      <div className="w-full flex flex-col gap-[10px] text-center">
        <input
          type="file"
          accept={fileType ? fileType : ".pdf"}
          name={inputName}
          required={required}
          onChange={handleUploadFile}
        />
      </div>
    </div>
  );
};

export default UploadFileCard;
