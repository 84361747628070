import React from "react";
import { Link } from "react-router-dom";
import Navlinks from "./Navlinks";
import MobileNav from "./MobileNav";

const Header = () => {
  return (
    <div>
      <div className="z-[20] fixed top-0 left-0 w-full bg-slate-200/75 flex items-center justify-center backdrop-blur-sm border-b border-b-slate-300 p-[10px]">
        <div className="w-[98%] lg:w-[85%] flex items-center justify-between">
          <Link to="/" className="flex items-center gap-[10px]">
            <img
              src="/RHEST_Logo.png"
              alt="logo"
              className="w-[50px] lg:w-[80px] h-[50px] lg:h-[80px] object-contain"
            />
            <p className="text-darkBlue text-4xl font-black tracking-wider">
              RHEST
            </p>
          </Link>

          <div>
            <Navlinks />
            <MobileNav />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
