import React, { useState } from "react";
import { RiFolderDownloadLine } from "react-icons/ri";
import { BsFiletypePdf } from "react-icons/bs";
import UploadDocument from "./UploadDocument";
import Overlay from "../Overlay";
import { RxCross2 } from "react-icons/rx";
import { useToastContext } from "../../context/ToastContext";
import Loader from "../Loader";
import GeneralPopup from "../Popup/GeneralPopup";
import { Link } from "react-router-dom";

const AppliedTenders = ({ data, token }) => {
  const { setShowToast, setToastMessage } = useToastContext();

  const [isDocumentUploadBtnClicked, setIsDocumentUploadBtnClicked] =
    useState(false);
  const [activeTenderDetails, setActiveTenderDetails] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [pending, setPending] = useState(false);

  return (
    <div className="w-full pl-[40px] lg:pl-0">

      {data?.length === 0 && (
        <div className="">
          <h1 className="text-3xl font-bold py-[20px] underline underline-offset-8">
            Applied Tenders
          </h1>
          <p>You have not applied to any recently opened tenders.</p>
          <Link
            to="/tender-list"
            className="w-max mt-[20px] font-semibold tracking-wide flex items-center px-[15px] py-[8px] rounded-[25px] bg-darkBlue text-white shadow-lg shadow-slate-500/50 text-start "
          >
            View Tenders List
          </Link>
        </div>
      )}
      {data?.length > 0 && (
        <div className="w-full flex flex-col gap-[10px] mt-[20px]">
          <h1 className="text-3xl font-bold py-[20px] underline underline-offset-8">
            Applied Tenders
          </h1>
          {data.map((item, index) => (
            <div key={index} className="w-full">
              <Link
                to={`/tender-details/${item.tender_details?.id}`}
                className="group flex items-center gap-[10px] hover:bg-blue-600 p-[8px]"
              >
                <div className="bg-gray-300 w-[35px] h-[35px] rounded-full flex items-center justify-center ">
                  {index + 1}
                </div>
                <h2 className="text-darkBlue font-semibold text-xl group-hover:text-white">
                  {item.tender_details?.tender_name}
                </h2>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AppliedTenders;
