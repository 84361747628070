import React from "react";

const Table = ({ data }) => {
  const tableData = Object.entries(data);

  return (
    <table className="w-full">
      <tbody className="w-full">
        {tableData.map(([key, value],index) => (
          <tr key={index} className="w-full">
            <td className="w-[40%] text-darkBlue font-semibold ">{key}</td>
            <td className="w-[60%] font-semibold">{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
