import React, { useTransition } from "react";
import { useState } from "react";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const token = queryParams.get("token");
  const email = queryParams.get("email");

  const [isPending, startTransition] = useTransition();
  const [formData, setFormData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const onFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetPassword = async () => {
    setIsSuccess(false);
    try {
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/reset-password-submit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token,
            email,
            password: formData.password,
            retype_password: formData.retype_password,
          }),
        }
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.status) {
            setIsSuccess(true);
            setMessage(res.message);
          } else {
            setIsSuccess(false);
            setMessage(res.message);
          }
        });
    } catch (err) {
      setIsSuccess(false);
      console.log(err);
    }
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (!token || !email) {
      setMessage("Unauthorized!");
      return;
    }

  // Check password length
  if (formData.password.length < 8) {
    setMessage("Password should be at least 8 characters long.");
    return;
  }

  if (formData.password !== formData.retype_password) {
    setMessage("Passwords do not match.");
    return;
  }

    startTransition(() => {
      resetPassword();
    });
  };

  return (
    <div className="w-full md:w-[50%] lg:w-[500px] min-h-[100vh] flex flex-col items-center gap-[30px] px-[20px] py-[30px]">
      <div>
        <img
          src="./RHEST_Logo.png"
          alt="Rhest_logo"
          className="w-[100px] h-[100px] rounded-full"
        />
      </div>
      <p className="text-4xl font-bold text-center">Reset Password</p>

      <form
        onSubmit={onFormSubmit}
        className="w-full flex flex-col gap-[20px] mt-[30px]"
      >
        <input
          type="password"
          placeholder="Enter New Password"
          name="password"
          required
          value={formData.password || ""}
          onChange={onFormChange}
          className="p-[8px] rounded-[4px] outline-none border border-slate-400 "
        />
        <input
          type="password"
          placeholder="Retype password"
          name="retype_password"
          required
          value={formData.retype_password || ""}
          onChange={onFormChange}
          className="p-[8px] rounded-[4px] outline-none border border-slate-400 "
        />
    {formData.retype_password && formData.password !== formData.retype_password && (
        <p className="text-red-700">* Passwords do not match.</p>
      )}
      {formData.password && formData.password.length < 8 && (
        <p className="text-red-700">* Password should be at least 8 characters long.</p>
      )}

        {message && !isSuccess && <p className="text-red-700">* {message}</p>}
        <div className="flex items-center gap-[20px] ">
          <button
            type="submit"
            disabled={isPending}
            className={`px-[20px] py-[8px] h-[45px] rounded-[4px] ${
              isPending ? "bg-darkBlue/50" : "bg-darkBlue"
            } text-white `}
          >
            {isPending ? <Loader /> : "Submit"}
          </button>
        </div>
      </form>

      {isSuccess && (
        <div className="w-full flex flex-col items-start">
          {message && <p className="text-green-700">{message}</p>}
          <Link to="/login" className="text-darkBlue text-lg font-semibold">
            Login
          </Link>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
