import React from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { RiForbid2Fill } from "react-icons/ri";
import { TbLogout2 } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../context/AuthContext";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { IoMdArrowDropright } from "react-icons/io";

const ProfileSidebar = ({
  userData,
  setPasswordChangeBtnClick,
  handleActiveSection,
}) => {
  const navigate = useNavigate();
  const { removeUser } = useUserContext();
  const { email, id, name, profile_photo_url, usertype } = userData;

  const handleLogout = () => {
    removeUser();
    navigate("/");
  };

  return (
    <section className="relative w-full h-full flex flex-col gap-[20px] items-start overflow-x-hidden border-r border-r-slate-400 px-[20px] xl:px-[50px] py-[20px]">
      {profile_photo_url && (
        <img
          src={profile_photo_url}
          alt="profilePhoto"
          className="w-[100px] h-[100px] rounded-full object-cover "
        />
      )}

      <div className="flex items-center gap-[5px]">
        <BsFillPersonFill className="text-darkBlue" />
        <p className="">{name}</p>
      </div>

      <div className="flex items-start gap-[5px]">
        <MdEmail className="text-darkBlue mt-[4px]" />
        <p className="break-all">{email}</p>
      </div>

      {usertype === "0" && (
        <div className="flex items-center gap-[5px]">
          <RiForbid2Fill className="text-darkBlue" />
          <p>
            User Type : <span>Bidder</span>
          </p>
        </div>
      )}

      <Link
        to="/tender-list"
        className="w-full font-semibold tracking-wide flex items-center px-[15px] py-[8px] rounded-[25px] bg-slate-200 shadow-lg shadow-slate-500/50 text-start "
      >
        Tenders List
      </Link>

      <button
        onClick={() => handleActiveSection("applied-tenders")}
        className="w-full font-semibold tracking-wide flex items-center px-[15px] py-[8px] rounded-[25px] bg-slate-200 shadow-lg shadow-slate-500/50 text-start "
      >
        {" "}
        Applied Tenders
      </button>
      <button
        onClick={() => handleActiveSection("company-details")}
        className="w-full font-semibold tracking-wide flex items-center px-[15px] py-[8px] rounded-[25px] bg-slate-200 shadow-lg shadow-slate-500/50 text-start "
      >
        Company Details
      </button>
      <button
        onClick={setPasswordChangeBtnClick}
        className="w-full font-semibold tracking-wide flex items-center px-[15px] py-[8px] rounded-[25px] bg-slate-200 shadow-lg shadow-slate-500/50 text-start "
      >
        Change Password
      </button>

      <div
        onClick={handleLogout}
        className="cursor-pointer flex items-center gap-[5px] p-[8px] bg-darkBlue rounded-[25px] text-white"
      >
        <TbLogout2 className="" />
        <p>Logout</p>
      </div>
    </section>
  );
};

export default ProfileSidebar;
