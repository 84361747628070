import React, { useEffect, useState } from "react";
import TendersTable from "./TendersTable";
import { IoIosArrowDown } from "react-icons/io";
import AuthPopup from "../Popup/AuthPopup";
import { useUserContext } from "../../context/AuthContext";
import GeneralPopup from "../Popup/GeneralPopup";
import DepositPopup from "../Popup/DepositPopup";
import Overlay from "../Overlay";
import ApplyDirectly from "../Popup/ApplyDirectly";

const RecentTenders = () => {
  const { user, token } = useUserContext();

  const [tenderList, setTenderList] = useState([]);
  const [activeId, setActiveId] = useState(0);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [diplayDepositPopup, setDiplayDepositPopup] = useState(false);
  const [activeTenderDetails, setActiveTenderDetails] = useState();
  const [bankDetails, setBankDetails] = useState([]);
  const [applyDirectly, setApplyDirectly] = useState(false);

  const getTendersData = async () => {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/categories`
      )
        .then(async (res) => await res.json())
        .then((res) => res);
      setTenderList(data.categories);
      return data.categories;
    } catch (err) {
      console.log(err);
    }
  };

  const getBankDetails = async () => {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/bank-details`
      )
        .then(async (res) => await res.json())
        .then((res) => res.bank_details);
      setBankDetails(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getBankDetails();
    getTendersData();
  }, []);

  return (
    <div className="w-full">
      <h1 className="text-xl font-bold underline underline-offset-8 decoration-darkBlue">
        RECENT TENDERS TO BID BY VENDORS.
      </h1>
      <div className="w-full mt-[30px] flex flex-col gap-[20px]">
        {tenderList?.length > 0 && (
          tenderList.map((item, index) => (
            <div key={index} className="">
              <div
                onClick={() => {
                  if (activeId === index) {
                    setActiveId();
                    return;
                  }
                  setActiveId(index);
                }}
                className="w-max group cursor-pointer flex items-center gap-[10px] py-[10px]"
              >
                <div className="p-[10px] w-[35px] h-[35px] flex items-center justify-center rounded-full bg-blue-900 text-white">
                  {index + 1}
                </div>
                <h3 className="group-hover:scale-105 group-hover:text-blue-900 text-xl font-semibold transition duration-150 ease-out">
                  {item?.name}
                </h3>
                <IoIosArrowDown className="text-blue-900" />
              </div>

              {activeId === index && item?.tender_lists?.length > 0 ? (
                <div className="lg:px-[20px]">
                  <TendersTable
                    data={item?.tender_lists}
                    isActive={activeId === index ? true : false}
                    displayAuthPopup={() => setShowAuthPopup(true)}
                    popAlreadyApplied={() => setAlreadyApplied(true)}
                    displayDepositPopup={(tenderDetails) => {
                      setDiplayDepositPopup(true);
                      setActiveTenderDetails(tenderDetails);
                    }}
                    bank_details={bankDetails}
                    applyDirectly={(tenderDetails) => {
                      setApplyDirectly(true);
                      setActiveTenderDetails(tenderDetails);
                    }}
                  />
                </div>
              ) : null}
            </div>
          ))
        )}
      </div>

      {showAuthPopup && (
        <AuthPopup
          handleCross={() => setShowAuthPopup(false)}
          redirectUrl="/tender-list"
        />
      )}

      {alreadyApplied && (
        <GeneralPopup
          text="You have already applied for this tender."
          btnName="Go to Profile"
          btnLink={`/profile/${user?.id}`}
          handleCross={() => {
            setAlreadyApplied(false);
          }}
        />
      )}

      {applyDirectly && (
        <ApplyDirectly
          handleCross={() => {
            setApplyDirectly(false);
          }}
          tenderDetails={activeTenderDetails}
          token={token}
          user={user}
        />
      )}

      {diplayDepositPopup && (
        <DepositPopup
          handleCross={() => {
            setDiplayDepositPopup(false);
          }}
          tenderDetails={activeTenderDetails}
        />
      )}

      {(diplayDepositPopup ||
        showAuthPopup ||
        alreadyApplied ||
        applyDirectly) && <Overlay />}
    </div>
  );
};

export default RecentTenders;
