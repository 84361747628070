import React, { useEffect } from "react";
import { BiDonateBlood } from "react-icons/bi";
import Baner1 from "../components/Banner/Baner1";
import RecentTenders from "../components/Tenders/RecentTenders";

const Home = () => {
  return (
    <div className="w-full min-h-[100vh] flex flex-col items-center gap-[50px]">
      {/* Recent Tenders */}
      <div className="mt-[50px] w-full lg:w-[80%] overflow-auto px-[20px]">
        <RecentTenders />
      </div>
    </div>
  );
};

export default Home;
