import React from "react";

const CompanyDetails = ({ data }) => {
  return (
    <div className="w-full">
      <h1 className="text-start text-2xl font-bold text-blue-500">Company Details</h1>
      <table className="w-full rounded-lg mt-[20px]">
        <tbody>
          <tr>
            <td className="font-semibold text-slate-700">Company Name</td>
            <td>{data.name}</td>
          </tr>
          <tr>
            <td className="font-semibold text-slate-700">Address</td>
            <td>{data.company_address}</td>
          </tr>
          <tr>
            <td className="font-semibold text-slate-700">City</td>
            <td>{data.city}</td>
          </tr>
          <tr>
            <td className="font-semibold text-slate-700">Website</td>
            <td>{data.website}</td>
          </tr>
          <tr>
            <td className="font-semibold text-slate-700">
              Registration Number
            </td>
            <td>{data.registration_no}</td>
          </tr>
          <tr>
            <td className="font-semibold text-slate-700">PAN/VAT Number</td>
            <td>{data.vat_or_pan}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CompanyDetails;
