import React from "react";

const InputWithLabel = ({ label,name,value, type, inputPlaceholder, required, handleInputChange }) => {


  const isMobileValid = name === "mobile" && value && value.length > 10;

  return (
    <div className="w-full flex flex-col gap-[5px] ">
      <p className="font-semibold">{label}</p>
      <input
        type={type}
        name={name}
        value={value || ""}
        required={required}
        placeholder={inputPlaceholder}
        onChange={handleInputChange}
        className="p-[8px] rounded-[4px] bg-slate-200 "
      />
            {isMobileValid && (
        <p className="text-red-500">Mobile number should not exceed 10 characters</p>
      )}
    </div>
  );
};

export default InputWithLabel;
