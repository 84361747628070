import React, { useState } from "react";
import { CgMenuRightAlt, CgProfile } from "react-icons/cg";
import { navLinks } from "../../data/navLinks";
import Overlay from "../Overlay";
import { RxCross2 } from "react-icons/rx";
import { useUserContext } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const MobileNav = () => {
  const navigate = useNavigate();

  const { user, isAuthenticated } = useUserContext();

  const queryParams = { redirect: "/" };
  const [isMenuIconDisplaying, setIsMenuIconDisplaying] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  
  return (
    <div className="lg:hidden flex items-center gap-[20px]">
      {isAuthenticated ? (
        <Link to={`/profile/${user?.id}`}>
          <div>
            <CgProfile className="text-[25px]" />
          </div>
        </Link>
      ) : (
        <div
          onMouseEnter={() => setHoveredId(4)}
          onMouseLeave={() => setHoveredId(null)}
          className="relative cursor-pointer flex items-center gap-[5px]"
        >
          <Link to={'/login'}>
            <CgProfile className="h-max text-[25px] py-[10px]" />
          </Link>
        </div>
      )}
      <div
        className="text-[25px] cursor-pointer"
        onClick={() => setIsMenuIconDisplaying(true)}
      >
        <CgMenuRightAlt />
      </div>

      {isMenuIconDisplaying && (
        <div
          className={`z-30 w-[75%] bg-white h-screen fixed top-0 right-0 ${
            isMenuIconDisplaying ? "translate-x-[0%]" : "translate-x-[100%]"
          } p-[20px]`}
        >
          <div
            onClick={() => setIsMenuIconDisplaying(false)}
            className="float-right cursor-pointer bg-darkBlue/75 text-white w-[35px] h-[35px] rounded-full flex items-center justify-center "
          >
            <RxCross2 />
          </div>
          {navLinks.length > 0 && (
            <ul className="mt-[40px] flex flex-col gap-[13px]">
              {navLinks.map((item, index) => (
                <Link key={index} to={item.link} onClick={()=>{
                  setIsMenuIconDisplaying(false)
                }}>{item.title}</Link>
              ))}
            </ul>
          )}
        </div>
      )}
      {isMenuIconDisplaying && <Overlay />}
    </div>
  );
};

export default MobileNav;
