import React, { useEffect, useState } from "react";
import { useUserContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import ProfileSidebar from "../components/ProfileSidebar";
import { getBidderProfile } from "../query/getQuery";
import ChangePasswordPopup from "../components/Popup/ChangePassword";
import withFormHandling from "../utils/formHandler";
import { usePasswordChange } from "../lib/react-query/queries";
import { useToastContext } from "../context/ToastContext";
import Overlay from "../components/Overlay";
import CompanyDetails from "../components/Bidder/CompanyDetails";
import CompanyContactPerson from "../components/Bidder/CompanyContactPerson";
import AppliedTenders from "../components/Bidder/AppliedTenders";
import Loader from "../components/Loader";
import SidebarForMobile from "../components/Bidder/SidebarForMobile";

const Profile = () => {
  const navigate = useNavigate();

  const { user, token, isAuthenticated, removeUser, setBidder_id } =
    useUserContext();
  const { setShowToast, setToastMessage } = useToastContext();

  const { mutateAsync: changePassword, isPending: isPasswordChanging } =
    usePasswordChange();

  const [data, setData] = useState();
  const [isPasswordChangeBtnClicked, setIsPasswordChangeBtnClicked] =
    useState(false);
  const [activeSection, setActiveSection] = useState("applied-tenders");
  const [passwordChangeErrorMessage, setPasswordChangeErrorMessage] =
    useState("");
  const [isMobileSidebarDisplaying, setIsMobileSidebarDisplaying] =
    useState(false);
  const [status, setStatus] = useState({
    pending: false,
    error: "",
    success: false,
  });

  // Fetch User data
  const fetchUserData = async () => {
    try {
      setStatus({
        pending: true,
        error: "",
        success: false,
      });

      await getBidderProfile(token).then((res) => {
        setStatus({
          pending: false,
          error: "",
          success: true,
        });
        if (res.data?.id) {
          localStorage.setItem("bidderId", JSON.stringify(res.id));
          setBidder_id(res.id);
        }
        setData(res);
      });
    } catch (err) {
      setStatus({
        pending: false,
        error: err.message,
        success: false,
      });
      console.log(err);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
    fetchUserData();
  }, []);

  const PasswordChangeFormWithHandling = withFormHandling(ChangePasswordPopup);

  // Submit password change form
  const submitPasswordChangeFormLogic = async (formData) => {
    if (!token) return;
    const info = {
      old_password: formData.old_password,
      new_password: formData.new_password,
      confirm_password: formData.confirm_password,
      token: token,
    };

    await changePassword(info).then((res) => {
      if (res.status) {
        removeUser();
        setShowToast(true);
        setToastMessage("Password Changed Successfully!");
        setIsPasswordChangeBtnClicked(false);
        navigate("/login");
      } else {
        if (res.errors?.new_password) {
          setPasswordChangeErrorMessage(res.errors?.new_password[0]);
        } else {
          setPasswordChangeErrorMessage(res.message);
        }
      }
    });
  };

  return (
    <div className="w-full min-h-[100vh] flex px-[20px]">
      {user && (
        <div
          className={`hidden z-10 bg-white lg:sticky top-[70px] w-[30%] h-[80vh] lg:flex justify-center transition duration-150 ease-in mr-[20px] `}
        >
          <ProfileSidebar
            userData={user}
            setPasswordChangeBtnClick={() =>
              setIsPasswordChangeBtnClicked(true)
            }
            handleActiveSection={(sectionId) => setActiveSection(sectionId)}
          />
        </div>
      )}

      {user && (
        <SidebarForMobile
          userData={user}
          handleActiveSection={(sectionId) => {
            setActiveSection(sectionId);
            setIsMobileSidebarDisplaying(false);
          }}
          setPasswordChangeBtnClick={() => {
            setIsPasswordChangeBtnClicked(true);
            setIsMobileSidebarDisplaying(false);
          }}
          isDisplaying={isMobileSidebarDisplaying}
          setIsDisplaying={() => setIsMobileSidebarDisplaying((prev) => !prev)}
        />
      )}

      {status.pending && (
        <div className="grow h-[70vh] flex items-center justify-center">
          <Loader />
        </div>
      )}

      {data && activeSection === "company-details" ? (
        <div className="pl-[30px] lg:pl-[40px] lg:pl-0 grow flex flex-col gap-[40px]">
          <CompanyDetails data={data} />
          <CompanyContactPerson data={data} handleEditSuccess={fetchUserData} />
        </div>
      ) : null}

      {activeSection === "applied-tenders" && (
        <AppliedTenders
          token={token}
          data={
            data?.applied_tenders_details?.some((item) => item === null)
              ? []
              : data?.applied_tenders_details
          }
        />
      )}

      {isPasswordChangeBtnClicked && (
        <PasswordChangeFormWithHandling
          onSubmit={submitPasswordChangeFormLogic}
          handleCross={() => setIsPasswordChangeBtnClicked(false)}
          isChangingPassword={isPasswordChanging}
          errorMessage={passwordChangeErrorMessage}
        />
      )}

      {isPasswordChangeBtnClicked && <Overlay />}
    </div>
  );
};

export default Profile;
