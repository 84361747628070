import React, { useState } from "react";
import CompanyDetailsForm from "../components/Forms/CompanyDetailsForm";
import CompanyContactPerson from "../components/Forms/CompanyContactPerson";
import { getCountryList } from "../query/getQuery";
import { useQuery } from "@tanstack/react-query";
import { useUserContext } from "../context/AuthContext";
import axios from "axios";
import SuccessPopup from "../components/Popup/SuccessPopup";
import Overlay from "../components/Overlay";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToastContext } from "../context/ToastContext";
import { useAppliedTendersContext } from "../context/AppliedTendersContext";

const BidderDetailsForm = () => {
  const navigate = useNavigate();

  const { setUser, setIsAuthenticated, setToken } = useUserContext();
  const { setShowToast, setToastMessage } = useToastContext();
  const { handleAppliedTender } = useAppliedTendersContext();

  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");

  // Fetching country list
  const countryList = useQuery({
    queryKey: ["tenders"],
    queryFn: getCountryList,
  });

  const [formData, setFormData] = useState();
  const [response, setResponse] = useState();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [status, setStatus] = useState({
    processing: false,
    error: "",
    updated: false,
  });
  const handleFormChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileSelect = (file, name) => {
    if (!file) return;

    setFormData((prevData) => ({
      ...prevData,
      [name]: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!acceptedTerms) {
      return;
    }

    const formInfo = new FormData();
    formInfo.append("bidder_name", formData.bidder_name);
    formInfo.append("address", formData.address);
    formInfo.append("city", formData.city);
    formInfo.append("website", formData.website);
    formInfo.append("vat_no_input", formData.vat_no_input);
    formInfo.append("f_name", formData.f_name);
    formInfo.append("l_name", formData.l_name);
    formInfo.append("position", formData.position);
    formInfo.append("mobile", formData.mobile);
    formInfo.append("phone1", formData.phone1);
    formInfo.append("contact_email", formData.contact_email);
    formInfo.append("company_reg_no_input", formData.company_reg_no_input);
    formInfo.append("registration_no", formData.registration_no);
    formInfo.append("vat_no_or_pan_no", formData.vat_no_or_pan_no);
    formInfo.append("company_logo", formData.company_logo);
    formInfo.append("tax_clearance", formData.tax_clearance);

    setStatus({
      processing: true,
      error: "",
      updated: false,
    });

    try {
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/bidder-profile-update/${userId}`,
        {
          method: "POST",
          body: formInfo,
        }
      )
        .then((response) => response.json())
        .then(async (res) => {
          if (res.token) {
            try {
              await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/api/v1/login-with-token`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    token: res.token,
                  }),
                }
              )
                .then((r) => r.json())
                .then((data) => {
                  setShowToast(true);
                  setToastMessage("Registered Successfully. Please login.");
                  navigate(`/login`);
                });
            } catch (err) {
              console.log(err);
            }
          }
          setStatus({
            processing: false,
            error: "",
            updated: true,
          });
          setResponse(res.data);
        });
    } catch (error) {
      console.log(error);
      setStatus({
        processing: false,
        error: error.message,
        updated: false,
      });
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="w-full min-h-[100vh] flex flex-col items-center gap-[30px] py-[30px]"
    >
      <h1 className="text-5xl font-bold">Bidder Details</h1>
      <div className="w-full flex flex-col items-center">
        <CompanyDetailsForm
          countryData={countryList?.data}
          handleFormChange={handleFormChange}
          handleFileSelect={handleFileSelect}
          formData={formData}
        />
      </div>

      <div className="w-full flex flex-col items-center mt-[40px]">
        <CompanyContactPerson
          formData={formData}
          handleFormChange={handleFormChange}
        />
      </div>

      <div className="flex items-center gap-[5px] mt-[30px]">
        <input
          id="checkbox"
          type="checkbox"
          onChange={() => setAcceptedTerms(!acceptedTerms)}
        />
        <label htmlFor="checkbox">
          I accept the{" "}
          <a href="/terms-and-conditions" className="text-blue-500">
            Terms and Conditions
          </a>
        </label>
      </div>

      <div>
        <button
          type="submit"
          disabled={status.processing}
          className="bg-darkBlue text-white px-[20px] py-[8px] rounded-[4px]"
        >
          {status.processing ? "Processing" : status.error ? "Retry" : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default BidderDetailsForm;
